import styled from 'styled-components';
import mq from '@/styles/js/utils/mq';
import {
  SegmentedControl as StyledSegmentedControl,
  SegmentedControlBg as StyledSegmentedControlBg,
  SegmentedControlOption as StyledSegmentedControlOption,
} from '@/components/forms/SegmentedControl.styles';
import Icon from '@/components/graphics/Icon';

export const ListIcon = styled(Icon).attrs({
  type: 'grid-24',
})`
  height: 1.9rem;
  width: 1.9rem;
  display: flex;
  align-items: center;
  margin-right: 1px;
`;

export const MapIcon = styled(Icon).attrs({
  type: 'map-24',
})`
`;

export const LabelContent = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ListOrMapToggle = styled.div`
  max-width: 14rem;
  width: fill-available;

  ${mq({ until: 1024 })} {
    max-width: none;
  }

  ${mq({ from: 768, until: 1024 })} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: ". one one";
  }

  &&& {
    flex-grow: 0;
  }

  ${StyledSegmentedControl} {
    ${mq({ from: 768, until: 1024 })} {
      grid-area: one;
    }
  }

  ${StyledSegmentedControlOption} {
    padding: 0.2rem 0.7rem;
    display: flex;
    align-items: center;

    ${mq({ until: 1024 })} {
      justify-content: center;
    }
  }

  ${StyledSegmentedControlBg} {
    ${mq({ from: 769 })} {
      right: -0.4rem;
    }
    ${mq({ until: 500 })} {
      right: -0.2rem;
    }
  }
`;
