import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import SvgUse from '@/components/graphics/SvgUse';

import ThemeContext from '@/context/Theme';

export const StyledCompanyLogo = styled.div`
  display: inline-block;
  overflow: hidden;
  align-content: center;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  > img,
  > svg {
    margin: auto;
    padding: 0;
  }
`;
export const StyledCompanyFullLogo = styled(StyledCompanyLogo)``;
export const StyledCompanyLogoMiniWhite = styled(StyledCompanyLogo)``;
export const StyledCompanySimplifiedImage = styled(StyledCompanyLogo)``;
export const StyledCompanyFullImage = styled(StyledCompanyLogo)``;

const CompanyLogo = ({ simplified, white, ...rest }) => {
  // Then figure out if we should change the logo based on available Teams
  // customization options
  const { customized, companyLogo, simplifiedLogo, colors } =
    React.useContext(ThemeContext);

  // Set default Deskpass logo, either full-size
  // or smaller flavor without text
  let logo = simplified ? (
    <StyledCompanyLogo>
      <SvgUse
        id={`deskpass-logo-mini${white ? '-white' : ''}`}
        category="full"
        width="11"
        height="12"
        {...rest}
      />
    </StyledCompanyLogo>
  ) : (
    <StyledCompanyFullLogo>
      <SvgUse
        id="deskpass-logo"
        category="full"
        width="118"
        height="33"
        {...rest}
      />
    </StyledCompanyFullLogo>
  );

  if (customized) {
    if (companyLogo || simplifiedLogo) {
      // Use custom logo if set
      logo =
        (simplified || !companyLogo) && simplifiedLogo ? (
          <StyledCompanySimplifiedImage>
            <img src={simplifiedLogo} alt="Company Logo" {...rest} />
          </StyledCompanySimplifiedImage>
        ) : (
          <StyledCompanyFullImage>
            <img src={companyLogo} alt="Company Logo" {...rest} />
          </StyledCompanyFullImage>
        );
    } else if (colors && colors.primary) {
      // Switch to white version of simplified logo if company colors are around
      // (white version is more likely to blend better with solid colors)
      logo = (
        <StyledCompanyLogoMiniWhite color={colors.primary}>
          <SvgUse
            id="deskpass-logo-mini-white"
            category="full"
            width="11"
            height="12"
            {...rest}
          />
        </StyledCompanyLogoMiniWhite>
      );
    }
  }

  return logo;
};

CompanyLogo.propTypes = {
  simplified: PropTypes.bool,
  white: PropTypes.bool,
};

CompanyLogo.defaultProps = {
  simplified: false,
  white: false,
};

export default CompanyLogo;
