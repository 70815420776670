import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import typeStyle from '@/styles/js/fonts/typeStyles';
import fluidSize from '@/styles/js/utils/fluidSize';
import { Icon as StyledIcon } from '@/components/graphics/Icon.styles';

const zIndexMoreView = 450;

/**
 * Styled MoreView
 * ---
 * Mobile-only view display additional navigation options.
 */
export const MoreView = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.text};
  z-index: ${zIndexMoreView};
  display: flex;
  flex-direction: column;
`;

export const MoreViewHeader = styled.header`
  ${fluidSize('lay-l', 'padding-top')}
  ${fluidSize('outer-l', 'padding-left')}
  ${fluidSize('outer-l', 'padding-right')}

  display: flex;
  justify-content: space-between;
`;

export const MoreViewLogout = styled(Link)`
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  > span {
    ${typeStyle('body-s')}

    font-weight: bold;
    color: white;
  }
`;

export const MoreViewAvatar = styled.span`
  width: 2.4rem;
  margin-right: 1.2rem;
`;

export const MoreViewNav = styled.nav`
  ${fluidSize('outer-l', 'padding-left')}
  ${fluidSize('outer-l', 'padding-right')}

  margin: auto 0;
`;

const linkStyles = css`
  ${typeStyle('h1')}

  display: block;
  color: white;
  margin-bottom: 2rem;

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const MoreViewNavItem = styled.a`
  ${linkStyles}
`;

export const MoreViewNavItemLink = styled(Link)`
  ${linkStyles}
`;

export const CloseButton = styled.button.attrs({
  type: 'button',
})`
  height: 2.4rem;
  width: 2.4rem;
  display: block;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  ${StyledIcon} * {
    color: white;
  }
`;

export const SignInWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 2rem;
  background: white;
  margin-bottom: 6rem;
  overflow: hidden;
  height: max-content;
  text-align: center;
  > * {
    padding: 1.2rem;
    margin: 0;
  }
  > :first-child {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
  > :not(:first-child) {
    color: ${({ theme }) => theme.colors.primary};
  }
`;