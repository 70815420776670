import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import CompanyLogo from '@/components/brand/CompanyLogo';
import GeocodingFilter from '@/components/filters/GeocodingFilter';
import LoadingContent from '@/components/helpers/LoadingContent';
import Avatar from '@/components/users/Avatar';

import { useTranslation } from '@/context/I18n';
import MapContext from '@/context/Map';
import RouterContext from '@/context/Router';
import UserContext from '@/context/User';

import useEvent from '@/hooks/useEvent';
import useRoutePathMatcher from '@/hooks/useRoutePathMatcher';
import useTransitionToDash from '@/hooks/useTransitionToDash';

import config from '@/lib/config';
import route from '@/lib/routes';

import * as S from './index.styles';

const { DASH_URL } = config;

/**
 * The primary navigation shown at larger device sizes.
 * Displays uniquely depending on whether a user
 * is logged in.
 *
 * You should typically use the default import for this component,
 * but we've also provided an unwrapped component useful for testing
 * and styleguide purposes available as a named component.
 */
const NavLarge = memo(({ simple }) => {
  const { replace: replaceRoute } = useHistory();
  const { setActiveMarkerId } = React.useContext(MapContext);
  const { authenticated } = React.useContext(UserContext);

  const showBackButton = useRoutePathMatcher(['spaceDetail', 'roomDetail']);
  const showSearch = useRoutePathMatcher(['spaceList', 'roomList']);

  const goToIndex = useEvent(() => {
    replaceRoute(route('index'));
  });

  return (
    <S.NavLarge>
      <S.Inner>
        <S.Left>
          {showBackButton && <S.BackButton onClick={goToIndex} />}

          <S.NavLogo>
            <S.Link
              to={route('index')}
              onClick={(e) => {
                e.stopPropagation();

                setActiveMarkerId(null);
                goToIndex();
              }}
            >
              <CompanyLogo />
            </S.Link>
          </S.NavLogo>

          {showSearch && <GeocodingFilter />}
        </S.Left>

        {!simple && (
          <S.NavItems>
            {authenticated ? <SignedInNav /> : <SignedOutNav />}
          </S.NavItems>
        )}
      </S.Inner>
    </S.NavLarge>
  );
});

const SignedInNav = memo(() => {
  const { Ts } = useTranslation();
  const { user, newRoomBookingMessages } = React.useContext(UserContext);
  const [{ loading }, transitionToDash] = useTransitionToDash();

  const goToDash = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      transitionToDash();
    },
    [transitionToDash],
  );

  return (
    <>
      {loading && <LoadingContent placement="cover" />}

      <S.NavItem>
        <S.NavLink to={route('spaceIndex')}>Spaces</S.NavLink>
      </S.NavItem>

      <S.NavItem>
        <S.NavLink to={route('reservationIndex')}>
          {!!newRoomBookingMessages.length && (
            <S.NotificationDot>
              {newRoomBookingMessages.length}
            </S.NotificationDot>
          )}
          {Ts('labels.booking')}
        </S.NavLink>
      </S.NavItem>

      <S.NavItem>
        <S.NavLink
          to={route('accountShow')}
          $withAvatar={!!user.profileImageURLDynamic}
        >
          {user.profileImageURLDynamic && (
            <S.LinkAvatar>
              <Avatar mini imageUrl={user.profileImageURLDynamic} />
            </S.LinkAvatar>
          )}
          Account
        </S.NavLink>

        <S.ItemSubNav>
          <S.SubNavLink to={route('accountShow')}>Edit Account</S.SubNavLink>

          {user.hasLinkedManager && (
            <S.SubNavLink as="a" href={DASH_URL} onClick={goToDash}>
              Manage Your Team
            </S.SubNavLink>
          )}

          <S.SubNavLink to={route('logout')}>Logout</S.SubNavLink>
        </S.ItemSubNav>
      </S.NavItem>
    </>
  );
});

const SignedOutNav = memo(() => {
  const { withinIframe } = React.useContext(RouterContext);

  return (
    <>
      <S.NavItem>
        <S.NavLink
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={route('benefits')}
        >
          Benefits & Pricing
        </S.NavLink>
      </S.NavItem>

      <S.NavItem>
        <S.NavLink to={route('spaceIndex')}>Spaces</S.NavLink>
      </S.NavItem>

      <S.NavItem>
        <S.NavLink to={route('login')}>Log In</S.NavLink>
      </S.NavItem>

      {!withinIframe && (
        <S.NavItem>
          <S.NavLink to={route('signup')} $strong>
            Sign Up
          </S.NavLink>
        </S.NavItem>
      )}
    </>
  );
});

NavLarge.defaultProps = {
  simple: false,
};

NavLarge.propTypes = {
  simple: PropTypes.bool,
};

export default NavLarge;
